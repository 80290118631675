import Head from "next/head";
import { ReactElement } from "react";

import DefaultLayout from "components/Layouts/DefaultLayout";

export default function AppRedirectToHome() {
  return (
    <Head>
      <meta content="0;URL=/404" httpEquiv="refresh" />
    </Head>
  );
}

AppRedirectToHome.getLayout = function getLayout(page: ReactElement) {
  return <DefaultLayout>{page}</DefaultLayout>;
};
